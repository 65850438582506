// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../shared/components/FaIcon.bs.js";
import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";
import * as Js_array from "../../../../node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../shared/utils/ArrayUtils.bs.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Js_promise from "../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as Pagination from "../../shared/utils/Pagination.bs.js";
import * as ReactUtils from "../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as OptionUtils from "../../shared/utils/OptionUtils.bs.js";
import * as GraphqlQuery from "../../shared/utils/GraphqlQuery.bs.js";
import * as LoadingSpinner from "../../shared/components/LoadingSpinner.bs.js";
import * as SkeletonLoading from "../../shared/components/SkeletonLoading.bs.js";
import * as MultiselectDropdown from "../../packages/multiselect-dropdown/src/components/MultiselectDropdown.bs.js";
import * as Notifications__Entry from "../types/Notifications__Entry.bs.js";
import * as Notifications__EntryCard from "./Notifications__EntryCard.bs.js";
import * as WebPushSubscriptionManager from "../../shared/WebPushSubscriptionManager.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.Notifications__List";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function eventName($$event) {
  if ($$event === "PostCreated") {
    return t(undefined, undefined, "filter.events.post_created_text");
  } else if ($$event === "SubmissionCreated") {
    return t(undefined, undefined, "filter.events.submission_created_text");
  } else {
    return t(undefined, undefined, "filter.events.topic_created_text");
  }
}

var Raw = {};

var query = "mutation MarkAllNotificationsMutation  {\nmarkAllNotifications  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.markAllNotifications;
  return {
          markAllNotifications: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.markAllNotifications;
  var value$2 = value$1.success;
  var markAllNotifications = {
    success: value$2
  };
  return {
          markAllNotifications: markAllNotifications
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var MarkAllNotificationsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var MarkAllNotificationsQuery_Graphql_error = include.Graphql_error;

var MarkAllNotificationsQuery_decodeNotification = include.decodeNotification;

var MarkAllNotificationsQuery_decodeObject = include.decodeObject;

var MarkAllNotificationsQuery_decodeNotifications = include.decodeNotifications;

var MarkAllNotificationsQuery_decodeErrors = include.decodeErrors;

var MarkAllNotificationsQuery_flashNotifications = include.flashNotifications;

var MarkAllNotificationsQuery_sendQuery = include.sendQuery;

var MarkAllNotificationsQuery_query = include.query;

var MarkAllNotificationsQuery_make = include.make;

var MarkAllNotificationsQuery = {
  MarkAllNotificationsQuery_inner: MarkAllNotificationsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  Graphql_error: MarkAllNotificationsQuery_Graphql_error,
  decodeNotification: MarkAllNotificationsQuery_decodeNotification,
  decodeObject: MarkAllNotificationsQuery_decodeObject,
  decodeNotifications: MarkAllNotificationsQuery_decodeNotifications,
  decodeErrors: MarkAllNotificationsQuery_decodeErrors,
  flashNotifications: MarkAllNotificationsQuery_flashNotifications,
  sendQuery: MarkAllNotificationsQuery_sendQuery,
  query: MarkAllNotificationsQuery_query,
  $$fetch: $$fetch,
  make: MarkAllNotificationsQuery_make
};

var Item = {};

var Pagination$1 = Pagination.Make(Item);

function updateNotification(id, notifications) {
  return Js_array.map((function (entry) {
                if (Notifications__Entry.id(entry) === id) {
                  return Notifications__Entry.markAsRead(entry);
                } else {
                  return entry;
                }
              }), notifications);
}

function markAllNotifications(notifications) {
  return Js_array.map((function (entry) {
                if (Belt_Option.isNone(Notifications__Entry.readAt(entry))) {
                  return Notifications__Entry.markAsRead(entry);
                } else {
                  return entry;
                }
              }), notifications);
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* UnsetSearchString */0 :
          var init = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: init.event,
                    title: undefined,
                    status: init.status
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* BeginLoadingMore */1 :
          return {
                  loading: /* LoadingMore */2,
                  entries: state.entries,
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* BeginReloading */2 :
          return {
                  loading: /* Reloading */1,
                  entries: state.entries,
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* ClearStatus */3 :
          var init$1 = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: init$1.event,
                    title: init$1.title,
                    status: undefined
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* ClearEvent */4 :
          var init$2 = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: undefined,
                    title: init$2.title,
                    status: init$2.status
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* SetSaving */5 :
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: true,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* ClearSaving */6 :
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: false,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* MarkAllNotifications */7 :
          var entries = state.entries;
          var tmp;
          tmp = typeof entries === "number" ? /* Unloaded */0 : (
              entries.TAG === /* PartiallyLoaded */0 ? ({
                    TAG: /* PartiallyLoaded */0,
                    _0: markAllNotifications(entries._0),
                    _1: entries._1
                  }) : ({
                    TAG: /* FullyLoaded */1,
                    _0: markAllNotifications(entries._0)
                  })
            );
          return {
                  loading: state.loading,
                  entries: tmp,
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: false,
                  totalEntriesCount: Belt_Option.isSome(state.filter.status) ? 0 : state.totalEntriesCount
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* SetSearchString */0 :
          var init$3 = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: init$3.event,
                    title: action._0,
                    status: init$3.status
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* UpdateFilterString */1 :
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: action._0,
                  filter: state.filter,
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* LoadNotifications */2 :
          var newTopics = action._2;
          var match = state.loading;
          var updatedTopics = match >= 2 ? Js_array.concat(Curry._1(Pagination$1.toArray, state.entries), newTopics) : newTopics;
          return {
                  loading: /* NotLoading */0,
                  entries: Curry._3(Pagination$1.make, updatedTopics, action._1, action._0),
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: state.saving,
                  totalEntriesCount: action._3
                };
      case /* SetStatus */3 :
          var init$4 = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: init$4.event,
                    title: init$4.title,
                    status: action._0
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* SetEvent */4 :
          var init$5 = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: action._0,
                    title: init$5.title,
                    status: init$5.status
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* MarkNotification */5 :
          var id = action._0;
          return {
                  loading: state.loading,
                  entries: Curry._2(Pagination$1.update, state.entries, (function (param) {
                          return updateNotification(id, param);
                        })),
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: state.saving,
                  totalEntriesCount: Belt_Option.isSome(state.filter.status) ? state.totalEntriesCount - 1 | 0 : state.totalEntriesCount
                };
      
    }
  }
}

var Raw$1 = {};

var query$1 = "query NotificationsFromNotificationsListQuery($search: String, $after: String, $event: NotificationEvent, $status: NotificationStatus)  {\nnotifications(event: $event, search: $search, first: 10, after: $after, status: $status)  {\nnodes  {\nactor  {\nid  \nname  \nfullTitle  \navatarUrl  \n}\n\ncreatedAt  \nevent  \nid  \nmessage  \nnotifiableId  \nnotifiableType  \nreadAt  \n}\n\npageInfo  {\nendCursor  \nhasNextPage  \n}\n\ntotalCount  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.notifications;
  var value$2 = value$1.nodes;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.endCursor;
  return {
          notifications: {
            nodes: Js_array.map((function (value) {
                    var value$1 = value.actor;
                    var tmp;
                    if (value$1 == null) {
                      tmp = undefined;
                    } else {
                      var value$2 = value$1.avatarUrl;
                      tmp = {
                        id: value$1.id,
                        name: value$1.name,
                        fullTitle: value$1.fullTitle,
                        avatarUrl: !(value$2 == null) ? value$2 : undefined
                      };
                    }
                    var value$3 = value.event;
                    var tmp$1;
                    switch (value$3) {
                      case "PostCreated" :
                          tmp$1 = "PostCreated";
                          break;
                      case "SubmissionCreated" :
                          tmp$1 = "SubmissionCreated";
                          break;
                      case "TopicCreated" :
                          tmp$1 = "TopicCreated";
                          break;
                      default:
                        tmp$1 = {
                          NAME: "FutureAddedValue",
                          VAL: value$3
                        };
                    }
                    var value$4 = value.notifiableId;
                    var value$5 = value.notifiableType;
                    var value$6 = value.readAt;
                    return {
                            actor: tmp,
                            createdAt: value.createdAt,
                            event: tmp$1,
                            id: value.id,
                            message: value.message,
                            notifiableId: !(value$4 == null) ? value$4 : undefined,
                            notifiableType: !(value$5 == null) ? value$5 : undefined,
                            readAt: !(value$6 == null) ? Caml_option.some(value$6) : undefined
                          };
                  }), value$2),
            pageInfo: {
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasNextPage: value$3.hasNextPage
            },
            totalCount: value$1.totalCount
          }
        };
}

function serialize$1(value) {
  var value$1 = value.notifications;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var pageInfo = {
    endCursor: endCursor,
    hasNextPage: value$4
  };
  var value$6 = value$1.nodes;
  var nodes = Js_array.map((function (value) {
          var value$1 = value.readAt;
          var readAt = value$1 !== undefined ? Caml_option.valFromOption(value$1) : null;
          var value$2 = value.notifiableType;
          var notifiableType = value$2 !== undefined ? value$2 : null;
          var value$3 = value.notifiableId;
          var notifiableId = value$3 !== undefined ? value$3 : null;
          var value$4 = value.message;
          var value$5 = value.id;
          var value$6 = value.event;
          var $$event = typeof value$6 === "object" ? value$6.VAL : (
              value$6 === "PostCreated" ? "PostCreated" : (
                  value$6 === "SubmissionCreated" ? "SubmissionCreated" : "TopicCreated"
                )
            );
          var value$7 = value.createdAt;
          var value$8 = value.actor;
          var actor;
          if (value$8 !== undefined) {
            var value$9 = value$8.avatarUrl;
            var avatarUrl = value$9 !== undefined ? value$9 : null;
            var value$10 = value$8.fullTitle;
            var value$11 = value$8.name;
            var value$12 = value$8.id;
            actor = {
              id: value$12,
              name: value$11,
              fullTitle: value$10,
              avatarUrl: avatarUrl
            };
          } else {
            actor = null;
          }
          return {
                  actor: actor,
                  createdAt: value$7,
                  event: $$event,
                  id: value$5,
                  message: value$4,
                  notifiableId: notifiableId,
                  notifiableType: notifiableType,
                  readAt: readAt
                };
        }), value$6);
  var notifications = {
    nodes: nodes,
    pageInfo: pageInfo,
    totalCount: value$2
  };
  return {
          notifications: notifications
        };
}

function serializeVariables$1(inp) {
  var a = inp.search;
  var a$1 = inp.after;
  var a$2 = inp.event;
  var a$3 = inp.status;
  return {
          search: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          event: a$2 !== undefined ? (
              a$2 === "PostCreated" ? "PostCreated" : (
                  a$2 === "SubmissionCreated" ? "SubmissionCreated" : "TopicCreated"
                )
            ) : undefined,
          status: a$3 !== undefined ? (
              a$3 === "Read" ? "Read" : "Unread"
            ) : undefined
        };
}

function makeVariables$1(search, after, $$event, status, param) {
  return {
          search: search,
          after: after,
          event: $$event,
          status: status
        };
}

function makeDefaultVariables$1(param) {
  return {
          search: undefined,
          after: undefined,
          event: undefined,
          status: undefined
        };
}

var NotificationsQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include$1.make;

var NotificationsQuery_Graphql_error = include$1.Graphql_error;

var NotificationsQuery_decodeNotification = include$1.decodeNotification;

var NotificationsQuery_decodeObject = include$1.decodeObject;

var NotificationsQuery_decodeNotifications = include$1.decodeNotifications;

var NotificationsQuery_decodeErrors = include$1.decodeErrors;

var NotificationsQuery_flashNotifications = include$1.flashNotifications;

var NotificationsQuery_sendQuery = include$1.sendQuery;

var NotificationsQuery_query = include$1.query;

var NotificationsQuery_fetch = include$1.$$fetch;

var NotificationsQuery = {
  NotificationsQuery_inner: NotificationsQuery_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables$1,
  Graphql_error: NotificationsQuery_Graphql_error,
  decodeNotification: NotificationsQuery_decodeNotification,
  decodeObject: NotificationsQuery_decodeObject,
  decodeNotifications: NotificationsQuery_decodeNotifications,
  decodeErrors: NotificationsQuery_decodeErrors,
  flashNotifications: NotificationsQuery_flashNotifications,
  sendQuery: NotificationsQuery_sendQuery,
  query: NotificationsQuery_query,
  $$fetch: NotificationsQuery_fetch,
  make: make
};

function markAllNotifications$1(send, $$event) {
  $$event.preventDefault();
  Curry._1(send, /* SetSaving */5);
  Js_promise.$$catch((function (param) {
          Curry._1(send, /* ClearSaving */6);
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (response) {
              if (response.markAllNotifications.success) {
                Curry._1(send, /* MarkAllNotifications */7);
              } else {
                Curry._1(send, /* ClearSaving */6);
              }
              return Promise.resolve(undefined);
            }), Curry._3($$fetch, undefined, undefined, undefined)));
}

function getEntries(send, cursor, filter) {
  var variables = makeVariables$1(filter.title, cursor, filter.event, filter.status, undefined);
  Js_promise.then_((function (response) {
          var newNotifications = Js_array.map(Notifications__Entry.makeFromJS, response.notifications.nodes);
          Curry._1(send, {
                TAG: /* LoadNotifications */2,
                _0: response.notifications.pageInfo.endCursor,
                _1: response.notifications.pageInfo.hasNextPage,
                _2: newNotifications,
                _3: response.notifications.totalCount
              });
          return Promise.resolve(undefined);
        }), Curry._3(make, undefined, undefined, variables));
}

function markNotification(send, notificationId) {
  return Curry._1(send, {
              TAG: /* MarkNotification */5,
              _0: notificationId
            });
}

function reloadEntries(state, send) {
  Curry._1(send, /* BeginReloading */2);
  getEntries(send, undefined, state.filter);
}

function computeInitialState(param) {
  return {
          loading: /* NotLoading */0,
          entries: /* Unloaded */0,
          filterString: "",
          filter: {
            event: undefined,
            title: undefined,
            status: "Unread"
          },
          saving: false,
          totalEntriesCount: 0
        };
}

function entriesList(caption, entries, send) {
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "text-xs text-gray-800 px-4 lg:px-8"
                }, caption), React.createElement("div", undefined, Js_array.map((function (entry) {
                        return React.createElement(Notifications__EntryCard.make, {
                                    entry: entry,
                                    markNotificationCB: (function (param) {
                                        return Curry._1(send, {
                                                    TAG: /* MarkNotification */5,
                                                    _0: param
                                                  });
                                      }),
                                    key: Notifications__Entry.id(entry)
                                  });
                      }), entries)));
}

function entriesLoadedData(totoalNotificationsCount, loadedNotificaionsCount) {
  return React.createElement("div", {
              className: "inline-block mt-2 mx-auto text-gray-800 text-xs px-2 text-center font-semibold"
            }, totoalNotificationsCount === loadedNotificaionsCount ? t([[
                      "total_notifications",
                      String(totoalNotificationsCount)
                    ]], undefined, "notifications_fully_loaded_text") : t([
                    [
                      "total_notifications",
                      String(totoalNotificationsCount)
                    ],
                    [
                      "loaded_notifications_count",
                      String(loadedNotificaionsCount)
                    ]
                  ], undefined, "notifications_partially_loaded_text"));
}

function label(s) {
  switch (s.TAG | 0) {
    case /* Event */0 :
        return t(undefined, undefined, "filter.label.event");
    case /* Status */1 :
        return t(undefined, undefined, "filter.label.status");
    case /* Title */2 :
        return t(undefined, undefined, "filter.label.title");
    
  }
}

function value(s) {
  switch (s.TAG | 0) {
    case /* Event */0 :
        return eventName(s._0);
    case /* Status */1 :
        var key = s._0 === "Read" ? "read" : "unread";
        return t(undefined, undefined, "filter.status." + key);
    case /* Title */2 :
        return s._0;
    
  }
}

var searchString = value;

function color(t) {
  switch (t.TAG | 0) {
    case /* Event */0 :
        return "blue";
    case /* Status */1 :
        if (t._0 === "Read") {
          return "green";
        } else {
          return "orange";
        }
    case /* Title */2 :
        return "gray";
    
  }
}

function $$event($$event$1) {
  return {
          TAG: /* Event */0,
          _0: $$event$1
        };
}

function title(search) {
  return {
          TAG: /* Title */2,
          _0: search
        };
}

function status(status$1) {
  return {
          TAG: /* Status */1,
          _0: status$1
        };
}

var Selectable = {
  label: label,
  value: value,
  searchString: searchString,
  color: color,
  $$event: $$event,
  title: title,
  status: status
};

var Multiselect = MultiselectDropdown.Make(Selectable);

function unselected(state) {
  var eventFilters = $$Array.map($$event, [
        "TopicCreated",
        "PostCreated",
        "SubmissionCreated"
      ]);
  var trimmedFilterString = $$String.trim(state.filterString);
  var title = trimmedFilterString === "" ? [] : [{
        TAG: /* Title */2,
        _0: trimmedFilterString
      }];
  var status = Js_array.map((function (s) {
          return {
                  TAG: /* Status */1,
                  _0: s
                };
        }), Belt_Option.mapWithDefault(state.filter.status, [
            "Read",
            "Unread"
          ], (function (u) {
              if (u === "Read") {
                return ["Unread"];
              } else {
                return ["Read"];
              }
            })));
  return Js_array.concat(status, Js_array.concat(title, eventFilters));
}

function defaultOptions(param) {
  return $$Array.map((function (s) {
                return {
                        TAG: /* Status */1,
                        _0: s
                      };
              }), [
              "Read",
              "Unread"
            ]);
}

function selected(state) {
  var selectedEventFilters = Belt_Option.mapWithDefault(state.filter.event, [], (function (e) {
          return [{
                    TAG: /* Event */0,
                    _0: e
                  }];
        }));
  var selectedSearchString = OptionUtils.mapWithDefault((function (title) {
          return [{
                    TAG: /* Title */2,
                    _0: title
                  }];
        }), [], state.filter.title);
  var status = Belt_Option.mapWithDefault(state.filter.status, [], (function (u) {
          return [{
                    TAG: /* Status */1,
                    _0: u
                  }];
        }));
  return Js_array.concat(status, Js_array.concat(selectedSearchString, selectedEventFilters));
}

function onSelectFilter(send, selectable) {
  switch (selectable.TAG | 0) {
    case /* Event */0 :
        return Curry._1(send, {
                    TAG: /* SetEvent */4,
                    _0: selectable._0
                  });
    case /* Status */1 :
        return Curry._1(send, {
                    TAG: /* SetStatus */3,
                    _0: selectable._0
                  });
    case /* Title */2 :
        return Curry._1(send, {
                    TAG: /* SetSearchString */0,
                    _0: selectable._0
                  });
    
  }
}

function onDeselectFilter(send, selectable) {
  switch (selectable.TAG | 0) {
    case /* Event */0 :
        return Curry._1(send, /* ClearEvent */4);
    case /* Status */1 :
        return Curry._1(send, /* ClearStatus */3);
    case /* Title */2 :
        return Curry._1(send, /* UnsetSearchString */0);
    
  }
}

function showEntries(entries, state, send) {
  var filteredEntries = Belt_Option.mapWithDefault(state.filter.status, entries, (function (u) {
          if (u === "Read") {
            return Js_array.filter((function (e) {
                          return Belt_Option.isSome(Notifications__Entry.readAt(e));
                        }), entries);
          } else {
            return Js_array.filter((function (e) {
                          return Belt_Option.isNone(Notifications__Entry.readAt(e));
                        }), entries);
          }
        }));
  var dates = ArrayUtils.distinct(Js_array.map((function (e) {
              return Notifications__Entry.createdAt(e).toDateString();
            }), filteredEntries));
  if (ArrayUtils.isEmpty(filteredEntries)) {
    return React.createElement("div", {
                className: "flex flex-col mx-auto bg-white rounded-md border p-6 justify-center items-center"
              }, React.createElement(FaIcon.make, {
                    classes: "fas fa-comments text-5xl text-gray-400"
                  }), React.createElement("h4", {
                    className: "mt-3 text-base md:text-lg text-center font-semibold"
                  }, t(undefined, undefined, "empty_notifications")));
  } else {
    return React.createElement("div", undefined, Js_array.map((function (d) {
                      var entries = Js_array.filter((function (e) {
                              return Notifications__Entry.createdAt(e).toDateString() === d;
                            }), filteredEntries);
                      var heading = d === new Date().toDateString() ? "Today" : d;
                      return ReactUtils.nullIf(entriesList(heading, entries, send), ArrayUtils.isEmpty(entries));
                    }), dates), React.createElement("div", {
                    className: "text-center pb-4"
                  }, entriesLoadedData(state.totalEntriesCount, filteredEntries.length)));
  }
}

function markAllNotificationsButton(state, send, entries) {
  var disabled = Belt_Array.every(entries, (function (e) {
          return Belt_Option.isSome(Notifications__Entry.readAt(e));
        }));
  return ReactUtils.nullIf(React.createElement("div", {
                  className: "flex w-full justify-end px-4 lg:px-8 -mb-5"
                }, React.createElement("button", {
                      className: "font-semibold text-xs underline px-2 py-1 rounded text-gray-800 hover:text-primary-500 hover:bg-gray-50 focus:outline-none focus:text-primary-500 focus:bg-gray-50 transition ease-in-out duration-150",
                      disabled: disabled || state.saving,
                      onClick: (function (param) {
                          return markAllNotifications$1(send, param);
                        })
                    }, t(undefined, undefined, "mark_all_as_read_button"))), ArrayUtils.isEmpty(entries));
}

function Notifications__List(Props) {
  var match = React.useReducer(reducer, {
        loading: /* NotLoading */0,
        entries: /* Unloaded */0,
        filterString: "",
        filter: {
          event: undefined,
          title: undefined,
          status: "Unread"
        },
        saving: false,
        totalEntriesCount: 0
      });
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          reloadEntries(state, send);
        }), [state.filter]);
  var entries = state.entries;
  var tmp;
  if (typeof entries === "number") {
    tmp = React.createElement("div", {
          className: "px-2 lg:px-8"
        }, SkeletonLoading.multiple(10, SkeletonLoading.card(undefined, undefined)));
  } else if (entries.TAG === /* PartiallyLoaded */0) {
    var cursor = entries._1;
    var entries$1 = entries._0;
    var match$1 = state.loading;
    var tmp$1;
    switch (match$1) {
      case /* NotLoading */0 :
          tmp$1 = React.createElement("div", {
                className: "px-4 lg:px-8 pb-6"
              }, React.createElement("button", {
                    className: "btn btn-primary-ghost cursor-pointer w-full",
                    onClick: (function (param) {
                        Curry._1(send, /* BeginLoadingMore */1);
                        getEntries(send, cursor, state.filter);
                      })
                  }, t(undefined, undefined, "button_load_more")));
          break;
      case /* Reloading */1 :
          tmp$1 = null;
          break;
      case /* LoadingMore */2 :
          tmp$1 = React.createElement("div", {
                className: "px-2 lg:px-8"
              }, SkeletonLoading.multiple(3, SkeletonLoading.card(undefined, undefined)));
          break;
      
    }
    tmp = React.createElement("div", undefined, markAllNotificationsButton(state, send, entries$1), showEntries(entries$1, state, send), tmp$1);
  } else {
    var entries$2 = entries._0;
    tmp = React.createElement("div", undefined, markAllNotificationsButton(state, send, entries$2), showEntries(entries$2, state, send));
  }
  var match$2 = state.entries;
  var tmp$2;
  if (typeof match$2 === "number") {
    tmp$2 = null;
  } else {
    var match$3 = state.loading;
    var loading = match$3 === 1;
    tmp$2 = React.createElement(LoadingSpinner.make, {
          loading: loading
        });
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "md:flex md:flex-row space-y-2 md:space-y-0 md:space-x-2 md:justify-between md:items-center pt-4 px-4 lg:px-8 bg-gray-50"
                }, React.createElement("div", {
                      className: "font-bold text-sm md:text-xl"
                    }, t(undefined, undefined, "notifications")), React.createElement(WebPushSubscriptionManager.make, {})), React.createElement("div", {
                  className: "w-full bg-gray-50 border-b sticky top-0 z-30 px-4 lg:px-8 py-3"
                }, React.createElement("label", {
                      className: "block text-tiny font-semibold uppercase ps-px ",
                      htmlFor: "search_notifcations"
                    }, t(undefined, undefined, "filter.input_label")), React.createElement(Multiselect.make, {
                      id: "search_notifcations",
                      placeholder: t(undefined, undefined, "filter.input_placeholder"),
                      onChange: (function (filterString) {
                          Curry._1(send, {
                                TAG: /* UpdateFilterString */1,
                                _0: filterString
                              });
                        }),
                      value: state.filterString,
                      unselected: unselected(state),
                      selected: selected(state),
                      onSelect: (function (param) {
                          return onSelectFilter(send, param);
                        }),
                      onDeselect: (function (param) {
                          return onDeselectFilter(send, param);
                        }),
                      hint: t(undefined, undefined, "filter.input_hint"),
                      defaultOptions: defaultOptions(undefined)
                    })), React.createElement("div", {
                  className: "mt-4",
                  id: "entries"
                }, tmp), tmp$2);
}

var make$1 = Notifications__List;

export {
  str ,
  t ,
  eventName ,
  MarkAllNotificationsQuery ,
  Item ,
  Pagination$1 as Pagination,
  updateNotification ,
  reducer ,
  NotificationsQuery ,
  markAllNotifications$1 as markAllNotifications,
  getEntries ,
  markNotification ,
  reloadEntries ,
  computeInitialState ,
  entriesList ,
  entriesLoadedData ,
  Selectable ,
  Multiselect ,
  unselected ,
  defaultOptions ,
  selected ,
  onSelectFilter ,
  onDeselectFilter ,
  showEntries ,
  markAllNotificationsButton ,
  make$1 as make,
}
/* include Not a pure module */
